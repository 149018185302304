<template>
  <section class="base-page">
    <section class="page-header base-shadow">
      <h3 class="header-title">
        <span class="header-title-name">
          <span class="title-icon">
            <svg-icon :iconClass="$route.meta.iconSvg"></svg-icon>
          </span>
          <span>合同详情 <el-tag type="success" size="mini" v-if="!detail.disabled">已启用</el-tag>
          <el-tag type="danger" size="mini" v-if="detail.disabled">已禁用</el-tag>
          </span>
        </span>

        <div class="page-control">
          <el-button
            size="small"
            v-if="detail.canUpdate"
            plain
            @click="onEdit"
            :disabled="loading"
            >修 改</el-button
          >
          <el-button
            size="small"
            type="success"
            @click="handleEnableContract"
            plain
            v-if="detail.disabled && detail.canDisable"
            :disabled="submitting"
            >启用</el-button
          >
          <el-button
          v-if="!detail.disabled && detail.canDisable"
            size="small"
            type="danger"
            @click="handleDisableContract"
            plain
            :disabled="submitting"
            >禁用</el-button
          >
        </div>
      </h3>
    </section>
    <section class="base-page-section base-shadow" v-loading="loading">
      <BaseDetail :detail="detail" />
      <!-- <el-form label-position="left" label-width="auto" :model="detail">
        <DetailBlock name="基本信息">
          <el-row :gutter="25">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="金额：">
                <span>{{ detail.amount | currency }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同开始日期：">
                <span>{{ detail.startDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同结束日期：">
                <span>{{ detail.endDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同过期日期：">
                <span>{{ detail.expireDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同到期提醒日期：">
                <span>{{ detail.expireRemindDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同签订地点：">
                <span>{{ detail.signAddress }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </DetailBlock>
        <DetailBlock name="客户相关">
          <el-row :gutter="25">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="客户：">
                <span>{{ detail.clientName }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="客户签字日期：">
                <span>{{ detail.clientSignDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="客户签字人姓名：">
                <span>{{ detail.clientSignatoryName }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="客户签字人职位：">
                <span>{{ detail.clientSignatoryPosition }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </DetailBlock>
        <DetailBlock name="其他">
          <el-row :gutter="25">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="备注：">
                <span>{{ detail.remark }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="特殊条款：">
                <span>{{ detail.term }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24">
              <el-form-item label="附件：">
                <div class="file-content-list" v-if="detail.files && detail.files.length > 0">
                  <template v-for="item in detail.files">
                    <FilesItem :item="item" :key="item.id" class="mt-r" />
                  </template>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </DetailBlock>
      </el-form> -->
    </section>
  </section>
</template>

<script>
import { ShowApiError } from "@/request/error";
import { GetContractsById } from "./api";
import BaseDetail from "./components/BaseDetail";
import commonControl from "./mixins/commonControl";
export default {
  components: { BaseDetail },
  mixins: [commonControl],
  data() {
    return {
      currentId: null,
      loading: false,
      submitting: false,
      detail: {},
    };
  },
  watch: {
    $route: {
      handler() {
        if (
          this.$route.name === "ContractsDetail" &&
          this.$route.params.id &&
          this.$route.params.id !== null
        ) {
          this.currentId = this.$route.params.id;
          this.getContractDetail(this.currentId);
        } else {
          this.currentId = null;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    getContractDetail(id = "") {
      GetContractsById(id)
        .then((res) => {
          this.detail = res.data;
        })
        .catch((err) => {
          ShowApiError("获取详情失败", err);
        });
    },
    onEdit() {
      this.$router.push({
        name: "ContractsForm",
        params: {
          id: this.currentId,
        },
      });
    },
    handleEnableContract() {
      this.submitting = true;
      this.ToggleDisableContract(this.currentId, false)
        .then(() => {
          this.submitting = false;
          this.getContractDetail(this.currentId);
        })
        .catch(() => {
          this.submitting = false;
        });
    },
    handleDisableContract() {
      this.submitting = true;
      this.ToggleDisableContract(this.currentId, true)
        .then(() => {
          this.submitting = false;
          this.getContractDetail(this.currentId);
        })
        .catch(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-page {
  text-align: left;
  ::v-deep {
    .el-form-item__label-wrap {
      margin-left: 0 !important;
    }
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
  .base-page-section {
    padding: 15px 30px;
    margin: 20px;
  }
  .page-header {
    position: relative;
    .header-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-title-name {
        flex: 1;
      }
    }
    .page-control {
      flex: auto;
      text-align: right;
    }
    .client-sttting-wrap {
      padding-top: 20px;
      ::v-deep {
        .el-input__inner {
          border-bottom: 0px solid transparent;
          border-radius: 0;
        }
      }
    }
  }
}
</style>