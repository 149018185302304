<template>
  <section class="form-block">
    <section class="form-block-title">
        <span class="title-name">{{ name }}</span>
      
      <div class="block-control"><slot name="right"></slot></div>
    </section>
    <section class="form-block-content">
      <slot></slot>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.form-block {
  box-sizing: border-box;
  background-color: #fff;
  margin: 0 0 20px 0;
  .form-block-title {
    box-sizing: border-box;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: left;
    margin: 0;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-name{
        flex: 1;
        text-align: left;
    }
    .block-control{
        flex: auto;
        text-align: right;
    }
  }
  .form-block-content {
    box-sizing: border-box;
    padding: 15px;
  }
}
</style>