<template>
  <div class="base-detail">
    <NoData v-if="!detail.id" />
     <el-form v-if="detail.id" label-position="left" label-width="auto" :model="detail">
        <DetailBlock name="合同基本信息">
          <el-row :gutter="25">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="金额：">
                <span>{{ detail.amount | currency }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="收款金额：">
                <span>{{ detail.totalPayed | currency }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同开始日期：">
                <span>{{ detail.startDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同结束日期：">
                <span>{{ detail.endDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同过期日期：">
                <span>{{ detail.expireDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同到期提醒日期：">
                <span>{{ detail.expireRemindDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="合同签订地点：">
                <span>{{ detail.signAddress }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </DetailBlock>
        <DetailBlock name="客户相关">
          <el-row :gutter="25">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="客户：">
                <span>{{ detail.clientName }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="客户签字日期：">
                <span>{{ detail.clientSignDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="客户签字人姓名：">
                <span>{{ detail.clientSignatoryName }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="客户签字人职位：">
                <span>{{ detail.clientSignatoryPosition }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </DetailBlock>
        <DetailBlock name="其他">
          <el-row :gutter="25">
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="备注：">
                <span>{{ detail.remark }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <el-form-item label="特殊条款：">
                <span>{{ detail.term }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24">
              <el-form-item label="附件：">
                <div class="file-content-list" v-if="detail.files && detail.files.length > 0">
                  <template v-for="item in detail.files">
                    <FilesItem :showCheckBox="false" :item="item" :key="item.id" class="mt-r" />
                  </template>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </DetailBlock>
      </el-form>
  </div>
</template>

<script>
import DetailBlock from "@/components/DetailBlock";
import NoData from "@/components/NoData";
import FilesItem from "@/views/filesModule/components/FilesItem";
export default {
  components: { FilesItem, DetailBlock, NoData },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tagSize: "medium",
    };
  },
};
</script>

<style>
</style>